/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from "react";
import addDays from "date-fns/addDays";
import { useSelector } from "react-redux";
import addMinutes from "date-fns/addMinutes";
import { useAppDispatch } from "../../store";
import styles from "./Inputs.module.scss";
import calculateNodeHeight from "./internal/calculateNodeHeight";
import getSizingData from "./internal/getSizingData";
import { getAuction } from "../../store/auctionModal/selectors";
import { Auction } from "../../types/Auction";
import { updateAuction } from "../../store/auctionModal/reducer";
import {
  selectAuctionSettings,
  selectUserInfo,
} from "../../store/user/selectors";
import { User } from "../../types/User";
import {
  AuctionSettings,
  AccountSettingsRequestParams,
  getAuctionSettings,
  getUserInfo,
} from "../../store/user/actions";

interface AuctionTitleProps {
  placeholder: string;
  value: string;
}

const AuctionTitleInput = ({ placeholder, value }: AuctionTitleProps) => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState(value);
  const [maxHeight, setMaxHeight] = useState(38);
  const auction = useSelector((state) => getAuction(state));
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const auctionSettings = useSelector((state) =>
    selectAuctionSettings(state)
  ) as AuctionSettings;

  useEffect(() => {
    if (userInfo) {
      const params = {
        store_id: userInfo.data.last_store_id,
      } as AccountSettingsRequestParams;
      dispatch(getAuctionSettings(params));
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  const handleChange = (e) => {
    const sizeData = getSizingData(e.target);
    const height = calculateNodeHeight(sizeData, e.target.value, 1, 3);
    setTitle(e.target.value);
    setMaxHeight(height[0]);
    handleAuctionUpdate();
  };

  const handleAuctionUpdate = () => {
    const auct = JSON.parse(JSON.stringify(auction)) as Auction;
    auct.name = title;
    // set all default values
    if (!auct.ends_at) {
      const endsAtDate = new Date();
      if (!auct.starts_at) {
        const daysPlusOne = addDays(
          endsAtDate,
          auctionSettings.duration_days || 7
        );
        auct.ends_at = daysPlusOne.toISOString();
      } else {
        const date = new Date(auct.starts_at) || new Date();
        const datePlusOne = addDays(date, auctionSettings.duration_days || 7);
        auct.ends_at = datePlusOne.toISOString();
      }
    }
    if (!auct.currency) {
      auct.currency = auctionSettings.currency || "CAD";
    }
    if (!auct.starts_at) {
      const startsAt = new Date();
      const startPlusTenMin = addMinutes(startsAt, 30);
      auct.starts_at = startPlusTenMin.toISOString();
    }
    if (!auct.item) {
      auct.item = {};
    }
    if (!auct.item.price) {
      auct.item = { ...auct.item, price: auctionSettings.price || 10 };
    }
    if (!auct.item.increment) {
      auct.item = { ...auct.item, increment: auctionSettings.increment || 1 };
    }
    if (!auct.description || !auct.description.common) {
      auct.description = { common: "" };
    }
    Object.preventExtensions(auct);

    dispatch(updateAuction(auct));
  };

  return (
    <div className={styles.auctionTitleInputContainer}>
      <textarea
        id="auctionTitleInput"
        onBlur={handleAuctionUpdate}
        aria-label="Auction Title"
        placeholder={placeholder}
        value={title}
        autoFocus
        onChange={(e) => handleChange(e)}
        style={{
          minHeight: maxHeight,
          maxHeight,
        }}
      />
    </div>
  );
};

export default AuctionTitleInput;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import Imgix from "react-imgix";
import classNames from "classnames";
import AuctionTitleInput from "../../../../../Inputs/AuctionTitleInput";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { useAppDispatch } from "../../../../../../store";
import {
  getAuction,
  getAuctionModalIsEditing,
} from "../../../../../../store/auctionModal/selectors";
import { getUserInfo } from "../../../../../../store/user/actions";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
} from "../../../../../../store/user/selectors";
import styles from "./AuctionSteps.module.scss";
import AuctionButtonsContainer from "../internal/AuctionButtonsContainer";
import { Market } from "../../../../../../types/Market";
import { MethodTypes } from "../../../../../../enums/MethodTypes";

const BidddyImage =
  "https://imgix.cosmicjs.com/3d375d20-c983-11ec-bea6-99436435ce05-BidfootBackground.png";

const AuctionCreationStep = ({ location }) => {
  const dispatch = useAppDispatch();
  const auction = useSelector((state) => getAuction(state));
  const userInfo = useSelector((state) => selectUserInfo(state));
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const isEditing = useSelector((state) =>
    getAuctionModalIsEditing(state)
  ) as boolean;
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const [selectedMarket, setSelectedMarket] = useState("");
  useEffect(() => {
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
  }, []);

  useEffect(() => {
    if (availableMarkets.length > 0) {
      const selectedAuctionMarket = availableMarkets.find(
        (market) => market.id === auction.market_id
      );
      if (selectedAuctionMarket) {
        setSelectedMarket(selectedAuctionMarket.name);
      }
    }
  }, [availableMarkets, auction]);

  const nextPage = () => {
    // HANDLE THIS
    if (isEditing) {
      navigate(`/dashboard/auctions/${auction.id}/add-settings/`, {
        state: { ...location },
      });
    } else {
      navigate("/dashboard/auctions/create/add-settings/", {
        state: { ...location },
      });
    }
  };

  const getLearnText = () => {
    if (auction.type === MethodTypes.code) {
      return "How Quick Start Plus Works";
    }
    return "How Schedule Auctions Work";
  };

  const getLearnPath = () => {
    if (auction.type === MethodTypes.code) {
      return "/learn/how-quick-start-plus-works/";
    }
    return "/learn/how-pre-schedule-auctions-work/";
  };

  return (
    <>
      <div className={styles.creationContainer}>
        <div
          className={classNames([
            styles.desktopOnly,
            styles.creationImageContainer,
          ])}
        >
          <Imgix
            src={`${BidddyImage}?auto=format`}
            height={570}
            width={570}
            htmlAttributes={{
              alt: "Enter a title",
              src: `${BidddyImage}?blur=100&px=2&auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
        </div>
        <div className={styles.textContainer}>
          <div>
            <h3>@{selectedMarket}</h3>
            <h2>
              Name Your
              <br /> Auction.
            </h2>
            <AuctionTitleInput
              value={auction ? auction.name : ""}
              placeholder="My Auction #04"
            />
          </div>
          {/* <GetStarted /> */}
          <div className={styles.learnContent}>
            <AuctionButtonsContainer
              allowsLocalLoading
              disabled={!auction || !auction.name}
              nextPage={() => nextPage()}
            />
            <Link to={getLearnPath()}>{getLearnText()}</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuctionCreationStep;

import React from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import AuctionCreationStep from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/steps/AuctionCreationStep";
import { AuthProvider } from "../../../../components/Auth/auth";
import DashboardLayout from "../../../../components/DashboardLayout";
import SimpleSpring from "../../../../components/Transitions/transitions";
import AuctionCreationNav from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/internal/AuctionCreationNav";
import {
  getAuction,
  getAuctionModalIsEditing,
} from "../../../../store/auctionModal/selectors";
import { Auction } from "../../../../types/Auction";
import { MethodTypes } from "../../../../enums/MethodTypes";

const AuctionCreateStep1 = ({ location }) => {
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const isEditing = useSelector((state) =>
    getAuctionModalIsEditing(state)
  ) as boolean;
  const getTotalSteps = () => {
    if (auction.type === MethodTypes.code) {
      return 4;
    }
    return 6;
  };

  const onBack = () => {
    if (!isEditing) {
      navigate("/dashboard/auctions/new/");
    } else {
      navigate(`/dashboard/auctions/${auction.id}/`);
    }
  };

  return (
    <AuthProvider>
      <SimpleSpring location={location}>
        <DashboardLayout
          hideFooter
          style={{ paddingTop: 0 }}
          hideNav
          title="Bidddy | Create New Auction - Add Title"
        >
          <AuctionCreationNav
            step={0}
            totalSteps={getTotalSteps()}
            onBack={() => onBack()}
            title="Name Auction"
          />
          <AuctionCreationStep location={location} />
        </DashboardLayout>
      </SimpleSpring>
    </AuthProvider>
  );
};

export default AuctionCreateStep1;
